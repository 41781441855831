import Typography, { TypographyVariants } from '@c/Typographies/Typography';
import { Modal, Box, Button, CircularProgress } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/actions/user.actions';
import { AppDispatch } from 'store/store';
import styles from './logout_modal.module.css';

type LogoutModalProps = {
  open: boolean;
  onClose: () => void;
};

export const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 0,
  borderRadius: '7px',
  display: 'flex',
  flexDirection: 'column',
  border: '0!important',
  outline: '0!important',
  gap: '15px',
  width: '600px',
  maxWidth: '90vw',
};

const LogoutModal = ({ open, onClose }: LogoutModalProps) => {
  const [logoutLoading, setLogoutLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const router = useRouter();

  const handleLogout = async () => {
    setLogoutLoading(true);
    await dispatch(logout(router));
    setLogoutLoading(false);
    router.push('/');
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalBoxStyle}>
        <div className={styles.logout_container}>
          <Typography variant={TypographyVariants.Text18Regular}>
            Вы уверены, что хотите выйти из своего аккаунта?
          </Typography>
          <div className={styles.logout_buttons_container}>
            <Button variant="outlined" onClick={onClose}>
              Отменить
            </Button>
            <Button variant="contained" onClick={() => handleLogout()}>
              {logoutLoading ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                'Выйти'
              )}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
