import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { persistCombineReducers } from 'redux-persist';
import { girlsReducer } from './reducers/girls.reducer';
import thunkMiddleware from 'redux-thunk';
import { userReducer } from './reducers/user.reducer';
import { notificationsReducer } from './reducers/notifications.reducer';
const storage = require('redux-persist/lib/storage').default;

const persistConfig = {
  key: 'nextjs',
  whitelist: ['user', 'girls', 'notifications'],
  storage,
};

const combinedReducer = persistCombineReducers(persistConfig, {
  // user: userReducer,
  // girls: girlsReducer,
  notifications: notificationsReducer,
});

const makeStore = ({ isServer }) => {
  if (isServer) {
    return createStore(combinedReducer, applyMiddleware(thunkMiddleware));
  } else {
    const { persistStore, persistReducer } = require('redux-persist');

    // const persistedReduce2 = persistReducer(persistConfig, combinedReducer);
    const persistedReducer = persistCombineReducers(persistConfig, {
      user: userReducer,
      girls: girlsReducer,
      notifications: notificationsReducer,
    });

    const store = createStore(
      persistedReducer,
      applyMiddleware(thunkMiddleware),
    );

    store.__persistor = persistStore(store);

    return store;
  }
};

export const wrapper = createWrapper(makeStore);

export type Store = ReturnType<typeof makeStore>;

export type RootState = ReturnType<Store['getState']>;

export type AppDispatch = any;
