import React, { useEffect, useMemo } from 'react';
import { NotificationType } from '../../../shared/interfaces/notification.interface';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import styles from './notification.module.css';
// import { useSpring, animated } from 'react-spring';
import CloseIcon from '@mui/icons-material/Close';
import Typography2 from '@c/Typographies/Typography2';
type NotificationProps = {
  type: NotificationType;
  message: string;
  onAccept: () => void;
  index: number;
  title: string;
};

const Notification: React.FC<NotificationProps> = (props) => {
  const { type, message, onAccept, title } = props;
  // const [animatedStyles] = useSpring(() => ({
  //   from: {
  //     transform: 'translateX(100%)',
  //   },
  //   to: {
  //     transform: 'translateX(0%)',
  //   },
  // }));

  useEffect(() => {
    setTimeout(() => {
      handleOnAccept();
    }, 10000);
  }, []);

  const icon = useMemo(() => {
    switch (type) {
      case NotificationType.Error:
        return (
          <ErrorIcon style={{ width: '24px', height: '24px' }} color="error" />
        );
      case NotificationType.Success:
        return (
          <SuccessIcon
            style={{ width: '24px', height: '24px' }}
            color="success"
          />
        );
      case NotificationType.Warning:
        return (
          <WarningIcon
            style={{ width: '24px', height: '24px' }}
            color="warning"
          />
        );
      default:
        return (
          <SuccessIcon
            style={{ width: '24px', height: '24px' }}
            color="success"
          />
        );
    }
  }, [type]);

  const handleOnAccept = async () => {
    if (onAccept) {
      onAccept();
    }
  };

  return (
    <div
      {...props}
      key={props.index}
      className={styles.notification}
      // style={animatedStyles}
    >
      <div className={styles.header_container}>
        <div className={styles.header}>
          {icon}
          <Typography2 weight="500" size="l">
            {title}
          </Typography2>
        </div>
        <CloseIcon
          color="textBold"
          className={styles.icon_button}
          onClick={handleOnAccept}
        />
      </div>
      <Typography2 weight="400" size="m">
        {message}
      </Typography2>
    </div>
  );
};

export default React.memo(Notification);
