import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { axiosInstance } from 'api/axios.interceptors';
import { fetchUserChats, GetChatResponse } from 'api/chat.service';
import {
  fetchSinglePreview,
  getOwnersPublishings,
  GirlData,
} from 'api/girl.service';
import { getUserBalance } from 'api/user.service';
import { NextRouter } from 'next/router';

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }: { email: string; password: string }) => {
    const response = await axiosInstance.post(
      '/user',
      {
        email: email,
        password: password,
      },
      {
        withCredentials: true,
      },
      //   // headers: {
      //   //   'Access-Control-Allow-Origin': '*',
      //   //   'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
      //   //   'Access-Control-Allow-Headers':
      //   //     'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      //   // },
      // },
    );
    return response.data;
  },
);

export const getUserInfo = createAsyncThunk('user/getUserInfo', async () => {
  const res = await axiosInstance.get('user/userinfo', {
    withCredentials: true,
  });
  return {
    data: res.data,
  };
});

export const logout = createAsyncThunk(
  'user/logout',
  async (router: NextRouter) => {
    await axiosInstance.get('/user/logout', {
      withCredentials: true,
    });
    // console.log('Logging out...');
    router.replace({ pathname: '/' });
  },
);

export const logoutLocal = createAction('logout/action');

export const getUserChats = createAsyncThunk(
  'user/chats',
  async (): Promise<GetChatResponse> => {
    const chats = await fetchUserChats();
    return chats;
  },
);

type getOwnersPublishingsProps = {
  page: number;
  callback: () => void;
};

export const getAllPublishingOfOwner = createAsyncThunk(
  'user/publishings',
  async ({ page, callback }: getOwnersPublishingsProps): Promise<any> => {
    const girls = await getOwnersPublishings(page);

    if (girls.length === 0) callback();
    const payload = {
      girls: girls,
      reset: page === 1,
    };

    if (girls?.length === 0) {
      callback();
    }

    return payload;
  },
);

export const increasePage = createAction('increasePage');

export const resetPagination = createAction('resetPagination');
export const toggleViewCard = createAction('toggleViewCard');
export const setView = createAction('setView');
export const toggleViewDay = createAction('toggleViewDay');
export const setViewDay = createAction('setViewDay');
export const toggleAuthModal = createAction('toggleAuthModal');
export const updateSinglePreview = createAsyncThunk(
  'user/updatePreview',
  async (girlId: string): Promise<GirlData> => {
    const girl = await fetchSinglePreview(girlId);
    return girl;
  },
);

export const updateUserBalance = createAsyncThunk(
  'user/updateBalance',
  async (): Promise<number> => {
    const balance = await getUserBalance();
    return Number(balance);
  },
);
