import { UserRole } from '@c/utils/role_mapper';
import { createReducer } from '@reduxjs/toolkit';
import { GirlData } from 'api/girl.service';
import {
  getAllPublishingOfOwner,
  getUserChats,
  getUserInfo,
  increasePage,
  login,
  logout,
  resetPagination,
  toggleViewCard,
  toggleViewDay,
  setView,
  setViewDay,
  toggleAuthModal,
  logoutLocal,
  updateSinglePreview,
  updateUserBalance,
} from 'store/actions/user.actions';

export type ChatMessage = {
  message: string;
  sender: string;
  sent_time: number;
};

export type UserChat = {
  id: string;
  chat_with: string;
  messages: ChatMessage[];
};

export type UserChatRequest = {
  id: string;
  request_from: string;
};

export type UserState = {
  loginLoading: boolean;
  loginError: string;
  userInfo: {
    sub: string;
    email: string;
    preferred_username: string;
    email_verified: boolean;
    type: typeof UserRole | '';
    balance: number;
    avatar: {
      path: string;
    };
  };
  userInfoError: string;
  userInfoLoading: boolean;
  chats: {
    userChats: UserChat[];
    userChatRequests: UserChatRequest[];
    userChatsLoading: boolean;
    userChatsError: string;
  };
  publishings: {
    publishingsLoading: boolean;
    publishingsError: string;
    userPublishings: GirlData[];
  };
  toggleView: boolean;
  showAuthModal: boolean;
};

const initialState: UserState = {
  loginLoading: false,
  loginError: '',
  userInfo: {
    sub: '',
    email: '',
    preferred_username: '',
    type: '',
    balance: 0,
    avatar: { path: '' },
    email_verified: false,
  },
  userInfoLoading: false,
  userInfoError: '',
  toggleView: false,
  chats: {
    userChats: [],
    userChatRequests: [],
    userChatsLoading: false,
    userChatsError: '',
  },
  publishings: {
    publishingsLoading: false,
    publishingsError: '',
    userPublishings: [],
  },
  showAuthModal: false,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(login.pending, (state) => {
    state.loginLoading = true;
    state.loginError = '';
  });

  builder.addCase(login.fulfilled, (state, { payload }) => {
    state.loginLoading = false;
    state.loginError = '';
    //@ts-ignore
    state.access_token = payload.access_token;
  });

  builder.addCase(login.rejected, (state) => {
    state.loginLoading = false;
    state.loginError = 'Login error occured';
  });

  builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
    state.userInfo = payload.data;
    state.userInfoLoading = false;
    state.userInfoError = '';
  });

  builder.addCase(getUserInfo.rejected, (state) => {
    state.userInfoLoading = false;
    state.userInfoError = '';
    state.userInfo = initialState.userInfo;
  });

  builder.addCase(getUserInfo.pending, (state) => {
    state.userInfoLoading = true;
    state.userInfoError = '';
  });

  builder.addCase(getUserChats.rejected, (state) => {
    state.chats.userChatsLoading = false;
    state.chats.userChatsError = 'Chats fetch error';
  });

  builder.addCase(getUserChats.pending, (state) => {
    state.chats.userChatsLoading = true;
    state.chats.userChatsError = '';
  });

  builder.addCase(getUserChats.fulfilled, (state, { payload }) => {
    state.chats.userChatsLoading = false;
    state.chats.userChatsError = '';
    if (Boolean(payload)) {
      state.chats.userChats = payload.chats;
      state.chats.userChatRequests = payload.requests;
    }
  });

  builder.addCase(getAllPublishingOfOwner.pending, (state) => {
    state.publishings.publishingsLoading = true;
    state.publishings.publishingsError = '';
  });

  builder.addCase(getAllPublishingOfOwner.rejected, (state) => {
    state.publishings.publishingsLoading = false;
    state.publishings.publishingsError = 'Publishings fetch error';
  });

  builder.addCase(getAllPublishingOfOwner.fulfilled, (state, action) => {
    const { girls, reset } = action.payload;
    state.publishings.userPublishings = reset
      ? [...girls]
      : [...state.publishings.userPublishings, ...girls];

    state.publishings.publishingsLoading = false;
    state.publishings.publishingsError = '';
  });

  builder.addCase(logoutLocal, (state) => {
    state.userInfo = initialState.userInfo;
    state.showAuthModal = true;
  });

  builder.addCase(logout.fulfilled, (state) => {
    state.userInfoLoading = false;
    state.userInfoError = '';
    state.userInfo = initialState.userInfo;
  });

  builder.addCase(logout.pending, (state) => {
    state.userInfoLoading = true;
    state.userInfoError = '';
    state.userInfo = initialState.userInfo;
  });

  builder.addCase(logout.rejected, (state) => {
    state.userInfoLoading = false;
    state.userInfoError = 'ERROR USER INFO';
    state.userInfo = initialState.userInfo;
  });

  builder.addCase(increasePage, (state) => {
    state.pagination.page += 1;
  });

  builder.addCase(resetPagination, (state) => {
    state.pagination.page = 1;
  });
  builder.addCase(toggleViewCard, (state) => {
    state.toggleView = !state.toggleView;
  });
  builder.addCase(setView, (state, action) => {
    state.toggleView = action.payload;
  });

  builder.addCase(toggleViewDay, (state) => {
    state.toggleViews = !state.toggleViews;
  });

  builder.addCase(setViewDay, (state, action) => {
    state.toggleViews = action.payload;
  });

  builder.addCase(toggleAuthModal, (state) => {
    state.showAuthModal = !state.showAuthModal;
  });

  builder.addCase(updateUserBalance.fulfilled, (state, action) => {
    state.userInfo.balance = action.payload;
  });

  builder.addCase(updateSinglePreview.fulfilled, (state, { payload }) => {
    state.publishings.userPublishings = state.publishings.userPublishings.map(
      (girl) => {
        if (payload.id === girl.id) {
          return payload;
        }

        return girl;
      },
    );
  });
});
