import React, { createContext, useState } from 'react';
import Notification from '../Notification';
import styles from './notification_provider.module.css';
import {
  INotification,
  NotificationType,
} from 'shared/interfaces/notification.interface';
export const NotificationContext = createContext<any>();

const NotificationProvider = (props) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const notify: void = (
    type: NotificationType,
    message: string,
    title: string,
  ): void => {
    const newNotification: INotification = {
      type,
      message,
      title,
    };
    setNotifications((prev) => [...prev, newNotification]);
  };

  const handleOnNotificationAccept = (notificationIndex: number) => {
    const filteredNotifications = notifications?.filter(
      (undefined, index) => index !== notificationIndex,
    );
    setNotifications(filteredNotifications);
  };

  return (
    <NotificationContext.Provider value={notify as unknown as () => void}>
      <div className={styles.notifications_container}>
        {notifications.map((notification: INotification, index) => (
          <Notification
            index={index}
            key={index}
            {...notification}
            onAccept={() => handleOnNotificationAccept(index)}
          />
        ))}
      </div>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
