import { GirlData } from '@c/types/girl.interface';
import { CreateGirlPageRequest } from './girl.service';
import { log } from './logger.utils';

export type PaginationState = {
  page: number;
};

const serializeArrayToFormData = (
  arrayName: string,
  array: any[],
  formData: FormData,
  stringify = true,
) => {
  array.map((arrayObject: any) => {
    if (stringify) {
      formData.append(arrayName, JSON.stringify(arrayObject));
    } else {
      formData.append(arrayName, arrayObject);
    }
  });
};

export const serializeGirlDataToFormData = (data: CreateGirlPageRequest) => {
  const formData = new FormData();
  Object.keys(data.girl).forEach((key) => {
    const objectKey = key as unknown as keyof GirlData;
    if (
      data.girl[objectKey] !== undefined &&
      data.girl[objectKey] !== '' &&
      key !== 'services' &&
      key !== 'contacts'
    ) {
      formData.append(key, data.girl[objectKey] as string);
    }
  });

  const filteredImagesFiles = data.media.map((image, index) => {
    return image.file;
  });

  const filteredImagesData = data.media.map((image) => {
    const o_data = { ...image, file: 'null', result: 'null' };
    return o_data;
  });

  serializeArrayToFormData('media', filteredImagesFiles, formData, false);
  serializeArrayToFormData('media_data', filteredImagesData, formData);
  serializeArrayToFormData('services', data.girl.services, formData);
  serializeArrayToFormData(
    'contacts',
    data.girl.contacts?.filter((contact) => contact.value),
    formData,
  );
  return formData;
};

export const getPaginationState = (pagination: PaginationState) => {
  if (pagination.page > 0) {
    return `?page=${pagination.page}`;
  } else {
    return '';
  }
};
