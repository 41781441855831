import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    textTransluent: { main: string };
    textBold: { main: string };
    success: { main: string };
    lightPurple: { main: string };
  }
  interface PaletteOptions {
    textTransluent: { main: string };
    textBold: { main: string };
    light: { main: string };
    success: { main: string };
    lightPurple: { main: string };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    textTransluent: true;
    textBold: true;
    true: true;
    success: true;
    lightPurple: true;
  }
  interface SliderPropsColorOverrides {
    textTransluent: true;
    textBold: true;
    light: true;
    success: true;
    lightPurple: true;
  }
}

const palette = createTheme();

export const theme = createTheme({
  palette: {
    primary: {
      main: '#D1273F',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#D1273F',
    },
    light: {
      main: '#eec8c8',
    },
    success: {
      main: '#4bb34b',
    },
    lightPurple: {
      main: '#2D203A',
    },
    textTransluent: { main: 'rgba(0, 12, 35, 0.7)' },
    textBold: { main: '#2D203A' },
  },
});
