import { useEffect } from 'react';

export const useFirebase = () => {
  const initializeFirebase = async () => {
    const firebaseConfig = {
      apiKey: 'AIzaSyBdJw9_rpql3Uv1T4W3GBv1Y8UkScAlzmQ',
      authDomain: 'projectx-ba43d.firebaseapp.com',
      projectId: 'projectx-ba43d',
      storageBucket: 'projectx-ba43d.appspot.com',
      messagingSenderId: '669357022381',
      appId: '1:669357022381:web:c6e8563d1ffa5e88c7ba24',
      measurementId: 'G-6ND3M4ZL0W',
    };
    const firebase = await import('firebase/app');
    const analytics = await import('firebase/analytics');
    const app = firebase.initializeApp(firebaseConfig);
    const anal = analytics.getAnalytics(app);
    anal.app.automaticDataCollectionEnabled = true;
  };

  useEffect(() => {
    initializeFirebase();
  }, []);
};
