import { MediaServiceData } from '@c/Settings/SettingsManagePublishings/CreateNewPublishingModal';
import { GirlData } from '@c/types/girl.interface';
import axios from 'axios';
import { imageConfigDefault } from 'next/dist/shared/lib/image-config';
import { useSelector } from 'react-redux';
import { SortOptions } from 'store/actions/girls.actions';
import { updateGirl, updateSinglePreview } from 'store/actions/user.actions';
import { AppDispatch, RootState } from 'store/store';
import { addParametersToUrl } from 'utils/query.utils';
import { axiosInstance } from './axios.interceptors';
import {
  getPaginationState,
  PaginationState,
  serializeGirlDataToFormData,
} from './girl.utils';

export type GirlTag = {
  id: string;
  name: string;
  rating?: number;
};

export type Verification = {
  action: string;
  girlId: string;
  verification_video: string;
};

export type GirlImage = any;

export type CreateGirlPageRequest = {
  girl: GirlData;
  images: File[];
  audioIntro?: Blob;
  imagesPreview: ArrayBuffer[];
};

export const createNewPublishing = async (
  data: CreateGirlPageRequest,
  isUpdate: boolean,
  dispatch: AppDispatch,
  onUploadProgress: (e: any) => void,
) => {
  const formData = serializeGirlDataToFormData(data);

  if (!isUpdate) {
    const res = await axiosInstance.post('/girl', formData, {
      withCredentials: true,
      onUploadProgress,
    });
    return res.data;
  } else {
    const res = await axiosInstance.patch('/girl/' + data.girl.id, formData, {
      withCredentials: true,
      onUploadProgress,
    });
    dispatch(updateSinglePreview(data.girl.id));
    return res.data;
  }
};

export const getOwnersPublishings = async (page: number) => {
  const finalUrl = addParametersToUrl('/girl', { page });

  const res = await axiosInstance.get(finalUrl, {
    withCredentials: true,
  });
  return res.data;
};

export const fetchBasicServices = async () => {
  const res = await axiosInstance.get('/girl/basic/services');
  return res.data;
};

export const fetchAllGirls = async (
  type: string,
  page: number,
  sortingOptions: SortOptions,
) => {
  const getType = () => {
    if (type === 'undefined') return 'all';
    return type;
  };

  const finalUrl = addParametersToUrl('/girl/girls/' + getType(), {
    page,
    ...sortingOptions,
  });

  const res = await axiosInstance.get(finalUrl, {
    withCredentials: true,
  });
  return res.data;
};

export const fetchGirlsSearch = async (
  data: any,
  type: string,
  page: number,
  sortingOptions: SortOptions,
) => {
  const getType = () => {
    if (type === 'undefined') return 'all';
    return type;
  };

  const finalUrl = addParametersToUrl('/girl/search/' + getType(), {
    page,
    ...sortingOptions,
  });

  const res = await axiosInstance.post(finalUrl, data);
  return res.data;
};

export const fetchGirlFullData = async (
  girlId: string,
  cookie?: string | undefined,
): Promise<GirlData> => {
  if (cookie) {
    const res = await axiosInstance.get('/girl/' + girlId, {
      withCredentials: true,
      headers: {
        Cookie: cookie,
      },
    });
    return res.data;
  } else {
    const res = await axiosInstance.get('/girl/' + girlId, {
      withCredentials: true,
    });
    return res.data;
  }
};

export const fetchIsOwner = async (girlId: string): Promise<GirlData> => {
  const res = await axiosInstance.get('/girl/owner/get/' + girlId, {
    withCredentials: true,
  });
  return res.data;
};

export const isFavorite = async (girlId: string): Promise<any> => {
  const res = await axiosInstance.get('/favorite/' + girlId, {
    withCredentials: true,
  });
  return res.data;
};

export const addFavorite = async (girlId: string): Promise<any> => {
  const res = await axiosInstance.post(
    '/favorite',
    { id: girlId },
    {
      withCredentials: true,
    },
  );
  return res.data;
};

export const deleteFavorite = async (girlId: string): Promise<any> => {
  const res = await axiosInstance.delete('/favorite/' + girlId, {
    withCredentials: true,
  });
  return res.data;
};

export const createVerificationRequest = async (
  girlId: string,
  file,
  code: string,
): Promise<any> => {
  const formData = new FormData();
  formData.append('video', file);
  formData.append('code', code);
  const res = await axiosInstance.post('/verify/' + girlId, formData, {
    withCredentials: true,
  });
  return res.data;
};

export const getVerificationInfo = async (girlId: string) => {
  const res = await axiosInstance.get('/verify/info/' + girlId, {
    withCredentials: true,
  });
  return res.data;
};

export const createUserTag = async (data: { name: string }) => {
  const res = await axiosInstance.post('/favorite/tags', data, {
    withCredentials: true,
  });
  return res.data;
};

export const getUserTags = async () => {
  const res = await axiosInstance.get('/favorite/tags', {
    withCredentials: true,
  });
  return res.data;
};

export type Tags = {
  id: string;
  name: string;
};

export const getFavoriteGirls = async (filterTags: Tags[], page: number) => {
  const finalUrl = addParametersToUrl('favorite/filter', {
    page,
  });
  const res = await axiosInstance.post(
    finalUrl,
    { tags: filterTags },
    {
      withCredentials: true,
    },
  );
  return res.data;
};

export const tagGirl = async (tagId: string, girlId: string) => {
  const finalUrl = addParametersToUrl('favorite/tag/' + girlId, { tagId });
  const res = await axiosInstance.put(finalUrl, undefined, {
    withCredentials: true,
  });
  return res.data;
};

export const untagGirl = async (tagId: string, girlId: string) => {
  const finalUrl = addParametersToUrl('favorite/untag/' + girlId, { tagId });
  const res = await axiosInstance.put(finalUrl, undefined, {
    withCredentials: true,
  });
  return res.data;
};

export const getGirlTags = async (girlId: string) => {
  const res = await axiosInstance.get('favorite/girl/' + girlId, {
    withCredentials: true,
  });
  return res.data;
};

export const deleteTag = async (tagId: string) => {
  const res = await axiosInstance.delete('favorite/tag/' + tagId, {
    withCredentials: true,
  });
  return res.data;
};

export const getGirlRating = async (girlId: string) => {
  const res = await axiosInstance.get('ratings/girl/' + girlId, {
    withCredentials: true,
  });
  return res.data;
};

export const girlPause = async (girlId: string) => {
  const res = await axiosInstance.put('service/pause/' + girlId, undefined, {
    withCredentials: true,
  });
  return res.data;
};

export const girlUnpause = async (girlId: string) => {
  const res = await axiosInstance.put('service/unpause/' + girlId, undefined, {
    withCredentials: true,
  });
  return res;
};

export const fetchSinglePreview = async (girlId: string) => {
  const res = await axiosInstance.get('girl/preview/' + girlId, {
    withCredentials: true,
  });
  return res.data;
};

export const getMostPopularTags = async () => {
  const res = await axiosInstance.get('tag/popular', { withCredentials: true });
  return res.data;
};

export const viewGirlImage = async (imageId: string) => {
  const res = await axiosInstance.post('girl/image/view/' + imageId);
  return res.data;
};

export const getReportingCategories = async () => {
  const res = await axiosInstance.get('reports', { withCredentials: true });
  return res.data;
};

export const reportGirl = async (id: string, type: string, message: string) => {
  const res = await axiosInstance.post(
    'reports/' + id,
    { type, message },
    { withCredentials: true },
  );
  return res.data;
};

export const apiFetchGirls = async (
  page: number,
  filter: any,
  search?: string,
) => {
  const finalUrl = addParametersToUrl('/girl/api2', {
    page,
  });
  const res = await axiosInstance.post(
    finalUrl,
    { ...filter, search },
    { withCredentials: true },
  );
  return res.data;
};

export const getGirlOfTheMonth = async () => {
  const res = await axiosInstance.get('/girl/top/monthly');
  return res.data;
};

export const getRecommendedGirls = async () => {
  const res = await axiosInstance.get('/girl/top/recommended');
  return res.data;
};

export const getPopularGirl = async () => {
  const res = await axiosInstance.get('/girl/top/popular');
  return res.data;
};

export const getNewGirls = async () => {
  const res = await axiosInstance.get('/girl/top/new');
  return res.data;
};

export const getMasseuses = async () => {
  const res = await axiosInstance.get('/girl/top/masseuses');
  return res.data;
};

export const getRelated = async (id: string) => {
  const res = await axiosInstance.get(`/girl/related/search/${id}`, {
    withCredentials: true,
  });
  return res.data;
};

export const getSimilarGirls = async (id: number) => {
  const res = await axiosInstance.get(`/girl/similar/search/${id}`);
  return res.data;
};

export const requestVerification = async (id: number, data: File[]) => {
  const formData = new FormData();
  data.forEach((mediaServiceData: MediaServiceData) => {
    formData.append('media', mediaServiceData?.file);
  });
  const res = await axiosInstance.post(
    `/girl/verification/create/${id}`,
    formData,
    { withCredentials: true },
  );
  return res.data;
};

export const getVerification = async (id: number) => {
  const res = await axiosInstance.get(`/girl/verification/list/${id}`, {
    withCredentials: true,
  });
  return res.data;
};

export const getGirlMapCoordinates = async (city: string) => {
  const res = await axiosInstance.get(`girl/api2/map/coordinates/${city}`, {
    withCredentials: true,
  });
  return res.data;
};

export const getMainPageData = async () => {
  const res = await axiosInstance.get('girl/initial/start');
  return res.data;
};

export const sitemapGetAllGirls = async () => {
  const res = await axiosInstance.get('girl/sitemap/list');
  return res.data;
};

export const checkPhoneExists = async (phone: string) => {
  const res = await axios.post(
    process.env.NEXT_PUBLIC_BACKEND_URL + '/girl/phone/exists',
    { phone },
    { withCredentials: true },
  );
  return res.data;
};

export const checkHasVerified = async () => {
  const res = await axiosInstance.get('/girl/allow/balance', {
    withCredentials: true,
  });
  return res.data;
};

export const checkHasVerifiedServer = async (cookie: string) => {
  const res = await axiosInstance.get('/girl/allow/balance', {
    withCredentials: true,
    headers: {
      Cookie: cookie,
    },
  });
  return res.data;
};
