import { originPosition } from '@c/Dialogs/LocationDialog';
import { GirlData } from '@c/types/girl.interface';
import { BasicService } from '@c/types/services.interface';
import { createReducer } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  clearFilterState,
  fetchGirls,
  getAllBasicServices,
  getFullGirlInfo,
  getGirlsSearch,
  logoutGirlState,
  resetGirlCreatingState,
  setGirlCreationState,
  setGirlFilterState,
  setGirlModalStateDraft,
  setIsManaging,
  setSelectedServices,
  setShowDraft,
  toggleSelectedFilter,
} from 'store/actions/girls.actions';

export type GirlState = {
  girls: GirlData[];
  girlsLoading: boolean;
  girlsError: string;
  page: number;
  filteredSearch: boolean;
  filters: any;
  filtersServices: Partial<BasicService>[];
  services: {
    basicServices: BasicService[];
  };
  girlModalState: GirlData;
  showDraft: boolean;
  girlModalStateDraft: GirlData;
  editGirlLoading: boolean;
  editGirlError: string;
  isManaging: boolean;
  foundCount: number;
};

const initialState: GirlState = {
  girls: [],
  foundCount: 0,
  girlsLoading: false,
  girlsError: '',
  page: 0,
  filteredSearch: false,
  filters: {},
  filtersServices: [],
  services: {
    basicServices: [],
  },
  editGirlLoading: false,
  editGirlError: '',
  isManaging: false,
  girlModalState: {
    id: '',
    name: '',
    descriptionHeader: '',
    description: '',
    extendedDescription: '',
    age: undefined,
    height: undefined,
    weight: undefined,
    breast: undefined,
    atHome: false,
    goOut: false,
    goMisc: false,
    verified: false,
    rating: {},
    price1_in_day: undefined,
    price2_in_day: undefined,
    price1_in_night: undefined,
    price2_in_night: undefined,
    price1_out_day: undefined,
    price2_out_day: undefined,
    price1_out_night: undefined,
    price2_out_night: undefined,
    price3_in: undefined,
    price3_out: undefined,
    services: [],
    contacts: [],
    locationCity: '',
    locationUnit: '',
    lookType: undefined,
    bodyType: undefined,
    hair: undefined,
    eyeColor: undefined,
    breastType: undefined,
    hairCut: undefined,
    pircing: undefined,
    tattoo: undefined,
    coords: originPosition,
    type: 'normal',
    indi: false,
    region: '',
    timeStart: '00:00',
    timeEnd: '00:00',
    format: 'saloon',
    tags: [],
  },
  showDraft: false,
  girlModalStateDraft: {
    id: '',
    name: '',
    descriptionHeader: '',
    description: '',
    extendedDescription: '',
    age: undefined,
    height: undefined,
    weight: undefined,
    breast: undefined,
    atHome: false,
    goOut: false,
    goMisc: false,
    verified: false,
    format: 'saloon',
    rating: {},
    price1_in_day: undefined,
    price2_in_day: undefined,
    price1_in_night: undefined,
    price2_in_night: undefined,
    price1_out_day: undefined,
    price2_out_day: undefined,
    price1_out_night: undefined,
    price2_out_night: undefined,
    price3_in: undefined,
    price3_out: undefined,
    services: [],
    contacts: [],
    locationCity: '',
    locationUnit: '',
    lookType: undefined,
    bodyType: undefined,
    hair: undefined,
    eyeColor: undefined,
    breastType: undefined,
    hairCut: undefined,
    pircing: undefined,
    tattoo: undefined,
    coords: originPosition,
    type: 'normal',
    indi: false,
    region: '',
    timeStart: '00:00',
    timeEnd: '00:00',
    tags: [],
  },
};

export const girlsReducer = createReducer(initialState, (builder) => {
  builder.addCase(HYDRATE, (state, action) => {
    alert('!!');
  });

  builder.addCase(fetchGirls.pending, (state) => {
    state.girlsLoading = true;
    state.girlsError = '';
  });

  builder.addCase(fetchGirls.fulfilled, (state, action) => {
    const { girls, reset, foundCount } = action.payload;
    state.girls = reset ? [...girls] : [...state.girls, ...girls];
    state.foundCount = foundCount;
    state.girlsLoading = false;
    state.girlsError = '';
  });

  builder.addCase(fetchGirls.rejected, (state) => {
    state.girlsLoading = false;
    state.girlsError = '';
  });

  builder.addCase(getAllBasicServices.fulfilled, (state, { payload }) => {
    state.services.basicServices = payload;
  });

  builder.addCase(getGirlsSearch.fulfilled, (state, action) => {
    const { girls, reset, foundCount } = action.payload;
    state.girls = reset ? [...girls] : [...state.girls, ...girls];
    state.girlsLoading = false;
    state.foundCount = foundCount;
    state.girlsError = '';
  });

  builder.addCase(getGirlsSearch.pending, (state, { payload }) => {
    state.girlsError = '';
    state.girlsLoading = true;
  });

  builder.addCase(getGirlsSearch.rejected, (state, { payload }) => {
    state.girlsError = 'FILTERED SEARCH ERROR';
    state.girlsLoading = false;
  });

  builder.addCase(setGirlCreationState, (state, { payload }) => {
    state.girlModalState = { ...state.girlModalState, ...payload };
  });

  builder.addCase(getFullGirlInfo.pending, (state, { payload }) => {
    state.editGirlLoading = true;
    state.editGirlError = '';
  });

  builder.addCase(getFullGirlInfo.rejected, (state, { payload }) => {
    state.editGirlLoading = false;
    state.editGirlError = 'Error loading girl';
  });

  builder.addCase(getFullGirlInfo.fulfilled, (state, { payload }) => {
    state.editGirlLoading = false;
    state.editGirlError = '';
    state.girlModalState = payload;
  });

  builder.addCase(setIsManaging, (state, { payload }) => {
    state.isManaging = payload;
  });

  builder.addCase(resetGirlCreatingState, (state) => {
    state.girlModalState = initialState.girlModalState;
  });

  builder.addCase(setGirlFilterState, (state, { payload }) => {
    const filterUndefinedKeys = {};
    Object.keys(payload).map((key) => {
      const undefinedValue = payload[key] === undefined;
      const emptyArray = payload[key]?.length === 0;
      if (!undefinedValue && !emptyArray) {
        filterUndefinedKeys[key] = payload[key];
      }
    });
    state.filters = { ...state.filters, ...payload };
  });

  builder.addCase(toggleSelectedFilter, (state, { payload }) => {
    const cleanFilters = state.filtersServices?.filter(
      (service) => service.short_id !== payload.short_id,
    );
    state.filtersServices = [...cleanFilters, payload];
  });

  builder.addCase(clearFilterState, (state) => {
    state.filters = {};
    state.filteredSearch = false;
    state.filtersServices = [];
  });

  builder.addCase(setSelectedServices, (state, { payload }) => {
    state.filtersServices = payload;
  });

  builder.addCase(setShowDraft, (state, { payload }) => {
    state.showDraft = payload;
  });

  builder.addCase(setGirlModalStateDraft, (state, { payload }) => {
    state.girlModalStateDraft = payload;
  });

  builder.addCase(logoutGirlState, (state) => {
    state = initialState;
  });
});
