import { UserChat, UserChatRequest } from 'store/reducers/user.reducer';
import { axiosInstance } from './axios.interceptors';

export type GetChatResponse = {
  chats: UserChat[];
  requests: UserChatRequest[];
};

export const sendChatRequest = async ({
  request_to,
}: {
  request_to: string;
}) => {
  const res = await axiosInstance.post(
    '/chat/requests',
    { request_to },
    { withCredentials: true },
  );
  return res;
};

export const fetchUserChats = async () => {
  const res = await axiosInstance.get('/chat', {
    withCredentials: true,
  });
  return res.data;
};

export const acceptChatRequest = async (id: string) => {
  return await axiosInstance.post(
    `/chat/requests/accept/${id}`,
    {},
    {
      withCredentials: true,
    },
  );
};

export const declineCharRequest = async (id) => {
  return await axiosInstance.post(
    `/chat/requests/decline/${id}`,
    {},
    {
      withCredentials: true,
    },
  );
};

export const authInSocket = async (id: number) => {
  const res = await axiosInstance.get('/chat/auth/' + id, {
    withCredentials: true,
  });
  return res.data;
};

export const updateSingleChat = async (id: number, page: number) => {
  const res = await axiosInstance.get(`/chat/${id}?page=${page}`, {
    withCredentials: true,
  });
  return res.data;
};

export const getChatMessages = async (
  id: number,
  page: number,
  older?: string,
) => {
  const res = await axiosInstance.get(
    `/chat/${id}/messages?page=${page}${older ? '&older=' + older : ''}`,
    {
      withCredentials: true,
    },
  );
  return res.data;
};

export const getNewMessages = async (id: number, lastTimestamp: number) => {
  const res = await axiosInstance.get(`/chat/${id}/new?last=${lastTimestamp}`, {
    withCredentials: true,
  });
  return res.data;
};
