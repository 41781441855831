import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutLocal } from 'store/actions/user.actions';
import { logoutGirlState } from '../store/actions/girls.actions';
import { toggleAuthModal } from '../store/actions/user.actions';
import { makeLogout } from '../utils/user.utils';
import { statisticsIdentity } from './statistics.service';

let identity = 'unknown_client';
if (typeof window !== 'undefined') {
  identity = statisticsIdentity();
}

let https = require('https');
const myagent = new https.Agent({
  rejectUnauthorized: false,
});

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
  headers: {
    s_identity: identity,
  },
  httpsAgent: myagent,
  NODE_TLS_REJECT_UNAUTHORIZED: '0',
});

export const useRequestInterceptors = async () => {
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    const identityInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        config.params = {
          ...config.params,
        };
        return config;
      },
    );

    const forbiddenInterceptor = axiosInstance.interceptors.response.use(
      undefined,
      (error) => {
        if (error.response?.status === 401) {
          router.push('/');
          dispatch(logoutGirlState());
          dispatch(logoutLocal());
          dispatch(toggleAuthModal());
          localStorage.setItem('persist:nextjs', '');
          localStorage.removeItem('persist:nextjs');
          if (router.pathname.includes('profile')) {
            router.replace({ pathname: '/' });
          }
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(forbiddenInterceptor);
      axiosInstance.interceptors.request.eject(identityInterceptor);
    };
  }, [router]);
};
