import ModalHeader from '@c/ModalComponents/ModalHeader/ModalHeader';
import { modalBoxStyle } from '@c/Settings/LogoutModal';
import { Box, Button, CircularProgress, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import styles from './location_dialog.module.css';

export const originPosition = [55.75, 37.57];

type LocationDialogProps = {
  setCoords: (arg1: number[]) => void;
  open: boolean;
  onClose: () => void;
  once?: boolean;
  defaultCoords?: number[];
};

const LocationDialog = ({
  setCoords,
  open,
  onClose,
  once = true,
  defaultCoords,
}: LocationDialogProps) => {
  const [loading, setLoading] = useState(true);
  const [placemark, setPlacemark] = useState(undefined);

  const handleClose = () => {
    setLoading(true);
    onClose();
  };

  useEffect(() => {
    if (defaultCoords) {
      setPlacemark(defaultCoords);
    } else {
      setPlacemark([55.75, 37.57]);
    }
  }, [defaultCoords]);

  const handleMapClick = (coordsNew: number[]) => {
    setPlacemark(coordsNew);
    setCoords(coordsNew);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalBoxStyle} style={{ padding: '25px' }}>
        <ModalHeader onClose={handleClose} title="Выберите Местоположение" />
        {loading && (
          <div
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {placemark ? (
          <YMaps width="300px" height="300px">
            <Map
              defaultState={{
                center: placemark ? placemark : [55.75, 37.57],
                zoom: 12,
              }}
              width="300px"
              height="300px"
              modules={[
                'templateLayoutFactory',
                'geoObject.addon.balloon',
                'clusterer.addon.balloon',
              ]}
              onLoad={() => setLoading(false)}
              onClick={(e) => handleMapClick(e.get('coords'))}
            >
              <Placemark geometry={placemark} />
            </Map>
          </YMaps>
        ) : null}
        <div className="flex-end">
          <Button variant="contained" onClick={onClose}>
            Сохранить Локацию
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default LocationDialog;
