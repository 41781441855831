import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const addParametersToUrl = (url: string, parameters: {}) => {
  let parametersQuery = '?';
  Object.keys(parameters).forEach((key, index) => {
    if (index === 0) {
      parametersQuery += key + '=' + String(parameters[key]);
    } else {
      parametersQuery += '&' + key + '=' + String(parameters[key]);
    }
  });
  return url + parametersQuery;
};

export const useProceessQueryParam = (
  field: string,
  onExecute: (arg1: any) => void,
) => {
  const router = useRouter();

  useEffect(() => {
    if (router.query[field]) {
      const newQurey = {};
      Object.keys(router.query).map((queryKey) => {
        if (queryKey !== field) {
          newQurey[queryKey] = router.query[queryKey];
        }
      });
      onExecute(router.query[field]);
      router.push({
        pathname: router.pathname,
        query: newQurey,
      });
    }
  }, [router.query]);
};
