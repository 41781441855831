import { createReducer } from '@reduxjs/toolkit';
import { fetchNotifications } from 'store/actions/notifications.actions';

export type Notifications = {
  id: string;
  title: string;
  read: boolean;
  message: string;
  section: string;
  type: string;
  date: Date;
};

export type NotificationsState = {
  notifications: Notifications[];
  notificationsLoading: boolean;
  notificationsError: string;
  notificationsMeta: {
    total: number;
    page: number;
    data: {
      new: number;
    };
  };
};

const initialState: NotificationsState = {
  notifications: [],
  notificationsLoading: false,
  notificationsError: '',
  notificationsMeta: {
    total: 0,
    page: 1,
    data: {
      new: 0,
    },
  },
};

export const notificationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchNotifications.pending, (state) => {
    state.notificationsLoading = true;
    state.notificationsError = '';
  });
  builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
    state.notificationsLoading = false;
    state.notifications =
      payload.meta.page === 1
        ? payload.items
        : [...state.notifications, ...payload.items];
    state.notificationsMeta = payload.meta;
    state.notificationsError = '';
  });
  builder.addCase(fetchNotifications.rejected, (state) => {
    state.notificationsLoading = false;
    state.notificationsError = 'Notifications error';
  });
});
