import axios from 'axios';
import { axiosInstance } from './axios.interceptors';

const statisticsBaisc = '/statistics';

export const initializeStatistics = async () => {
  let identity = localStorage.getItem('statistics_identity');
  if (identity === null) {
    identity = await getIdentity();
    localStorage.setItem('statistics_identity', String(identity));
  }
  return identity;
};

export const statisticsIdentity = () => {
  if (!statisticsIdentity) {
    return 'unknown_client';
  }
  try {
    const identity = localStorage?.getItem('statistics_identity');
    if (identity === null) {
      return 'unknown_client';
    } else {
      return identity;
    }
  } catch {
    return 'unknown_client';
  }
};

export const getIdentity = async () => {
  const res = await axiosInstance.get('/statistics/identity', {
    withCredentials: true,
  });
  return res.data;
};

export const recordEvent = async (type: string, data?: any) => {
  const res = await axiosInstance.post(
    statisticsBaisc + '/record',
    { type, ...data },
    { withCredentials: true },
  );
  return res.data;
};

export const getGirlStatistics = async (girlId: string) => {
  const res = await axiosInstance.get(statisticsBaisc + '/' + girlId, {
    withCredentials: true,
  });
  return res.data;
};
