import React from 'react';
import styles from './typography.module.css';

export const enum TypographyVariants {
  TextSmallLight = 'TextSmallLight',
  TextSmall = 'TextSmall',
  TextSmallMedium = 'TextSmallMedium',
  TextSmallBold = 'TextSmallBold',
  TextSmallBlack = 'TextSmallBlack',

  TextLargeLight = 'TextLargeLight',
  TextLarge = 'TextLarge',
  TextLargeMedium = 'TextLargeMedium',
  TextLargeBold = 'TextLargeBold',
  TextLargeBlack = 'TextLargeBlack',

  TextLight = 'TextLight',
  Text = 'Text',
  TextMedium = 'TextMedium',
  TextBold = 'TextBold',
  TextBlack = 'TextBlack',

  Text22Bold = 'Text22Bold',
  Text18Regular = 'Text18Regular',
  Text18Medium = 'Text18Medium',
  Text14Medium = 'Text14Medium',
  Text32Semi = 'Text32Semi',
  Text32Regular = 'Text32Regular',
  Text14Regular = 'Text14Regular',
  Text16Regular = 'Text16Regular',
  Text16Medium = 'Text16Medium',
}

type TypographyProps = {
  children: string | React.ReactChildren | any;
  className?: string;
  variant: TypographyVariants;
  style?: any;
  onClick?: () => void;
};

const Typography = ({
  children,
  className,
  variant,
  style,
  onClick,
}: TypographyProps) => {
  const styleTranslation = {
    [TypographyVariants.TextSmallLight]: styles.textSmallLight,
    [TypographyVariants.TextSmall]: styles.textSmall,
    [TypographyVariants.TextSmallMedium]: styles.textSmallMedium,
    [TypographyVariants.TextSmallBold]: styles.textSmallBold,
    [TypographyVariants.TextSmallBlack]: styles.textSmallBlack,

    [TypographyVariants.TextLight]: styles.textLight,
    [TypographyVariants.Text]: styles.text,
    [TypographyVariants.TextMedium]: styles.textMedium,
    [TypographyVariants.TextBold]: styles.textBold,
    [TypographyVariants.TextBlack]: styles.textBlack,

    [TypographyVariants.TextLargeLight]: styles.textLargeLight,
    [TypographyVariants.TextLarge]: styles.textLarge,
    [TypographyVariants.TextLargeMedium]: styles.textLargeMedium,
    [TypographyVariants.TextLargeBold]: styles.textLargeBold,
    [TypographyVariants.TextLargeBlack]: styles.textLargeBlack,

    [TypographyVariants.textLogo]: styles.textLogo,
    [TypographyVariants.textTopSection]: styles.textTopSection,
    [TypographyVariants.textButton]: styles.textButton,
    [TypographyVariants.Text22Bold]: styles.text22Bold,
    [TypographyVariants.Text18Regular]: styles.text18Regular,
    [TypographyVariants.Text18Medium]: styles.text18Medium,
    [TypographyVariants.Text14Medium]: styles.text14Medium,
    [TypographyVariants.Text32Semi]: styles.text32Semi,
    [TypographyVariants.Text32Regular]: styles.text32Regular,
    [TypographyVariants.Text14Regular]: styles.text14Regular,
    [TypographyVariants.Text16Regular]: styles.text16Regular,
    [TypographyVariants.Text16Medium]: styles.text16Medium,
  };

  return (
    <div
      className={styleTranslation[variant]}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : undefined }}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </div>
  );
};

export default Typography;
