import React from 'react';
import { theme } from 'theme/theme';
import { monteserrat } from '../../../pages/_app';

type Typography2Props = {
  base?: string;
  children: any;
  weight?: string;
  size?: string;
  color?: string;
  className?: string;
  onClick?: (e: any) => void;
};

const Typography2 = ({
  base,
  children,
  weight,
  size,
  color,
  className,
  onClick,
}: Typography2Props) => {
  const fontWeight = weight ? `font-weight-${weight}` : 'font-weight-400';
  const fontSize = size ? `font-size-${size}` : 'font-weight-m';
  const styles = { color: color ? color : theme.palette.textBold.main };
  const compiledClass = `${fontSize} ${fontWeight} ${className} ${monteserrat.className}`;
  switch (base) {
    case 'h1':
      return (
        <h1 className={compiledClass} style={styles}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={compiledClass} style={styles}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={compiledClass} style={styles}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={compiledClass} style={styles}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={compiledClass} style={styles}>
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6 className={compiledClass} style={styles}>
          {children}
        </h6>
      );
    case 'p':
      return (
        <p className={compiledClass} style={styles}>
          {children}
        </p>
      );
    case 'div':
      return (
        <div className={compiledClass} style={styles}>
          {children}
        </div>
      );
    default:
      return (
        <div
          className={compiledClass}
          style={styles}
          onClick={onClick ? onClick : null}
        >
          {children}
        </div>
      );
  }
};

export default Typography2;
