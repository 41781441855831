import { GirlData } from '@c/types/girl.interface';
import { BasicService } from '@c/types/services.interface';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAllGirls,
  fetchBasicServices,
  fetchGirlFullData,
  fetchGirlsSearch,
} from 'api/girl.service';

// export const getAllGirls = createAsyncThunk(
//   'girl/fetch',
//   (
//     type: string,
//     page: number,
//     callback: () => void,
//   ): Promise<{
//     girls: GirlData[];
//     reset: boolean;
//   }> => {
//     return new Promise(async (resolve) => {
//       const girls = await fetchAllGirls(type);
//       if (girls.length === 0) callback();
//       const payload = {
//         girls: girls,
//         reset: page === 0 || page === 1,
//       };
//       resolve(payload);
//     });
//   },
// );

export type SortOptions = {
  field: string;
  order: string;
};

type fetchGirlsParams = {
  type: string;
  page: number;
  callback: () => void;
  sorting: SortOptions;
};

export const fetchGirls = createAsyncThunk(
  'girl/fetch',
  async ({ type, page, callback, sorting }: fetchGirlsParams) => {
    const girls = await fetchAllGirls(type, page, sorting);
    if (girls.length === 0 && page > 1) callback();
    const payload = {
      girls: girls?.[0],
      reset: page === 1,
      foundCount: girls[1],
    };
    return payload;
  },
);

export const getAllBasicServices = createAsyncThunk(
  'girl/basic_services',
  async () => {
    const services = await fetchBasicServices();
    return services;
  },
);

type GirlSearchOptions = {
  data: any;
  callback: () => void;
  page: number;
  sorting: SortOptions;
  type: string;
};

export const getGirlsSearch = createAsyncThunk(
  'girl/search',
  async ({ data, callback, page, sorting, type }: GirlSearchOptions) => {
    const girls = await fetchGirlsSearch(data, type, page, sorting);
    if (girls.length === 0) callback();
    const payload = {
      girls: girls?.[0],
      reset: page === 1,
      foundCount: girls[1],
    };
    return payload;
  },
);

export const setGirlCreationState = createAction(
  'setGirlCreationState',
  (data: Partial<GirlData>) => {
    return { payload: data };
  },
);

export const setGirlModalStateDraft = createAction(
  'setGirlModalStateDraft',
  (data: Partial<GirlData>) => {
    return { payload: data };
  },
);

export const setShowDraft = createAction('setShowDraft', (data: boolean) => {
  return { payload: data };
});

export const setIsManaging = createAction('setIsManaging', (isManaging) => {
  return { payload: isManaging };
});

export const getFullGirlInfo = createAsyncThunk(
  'girl/info',
  async (girlId: string) => {
    const girlData = await fetchGirlFullData(girlId);
    return girlData;
  },
);

export const resetGirlCreatingState = createAction('girl/resetCreating');

export const toggleSelectedFilter = createAction(
  'girlFilter/services',
  (data: BasicService) => {
    return { payload: data };
  },
);

export const setSelectedServices = createAction(
  'girlFilter/setSelectedServices',
  (data: { short_id: number }[]) => {
    return { payload: data };
  },
);

export const setGirlFilterState = createAction(
  'girlFilter/girl',
  (data: Partial<GirlData>) => {
    return { payload: data };
  },
);

export const clearFilterState = createAction('girlFilter/clear');

export const toggleCardsView = createAction('toggleCardView');

export const logoutGirlState = createAction('logout/girlState');
