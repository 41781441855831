import { useEffect } from 'react';
import { useRouter } from 'next/router';
import routeConfig from './routeConfig.json';
import { axiosInstance } from 'api/axios.interceptors';

const defaultUnprotectedRouteRedirect = '/';

export const useProtected = (children) => {
  const router = useRouter();
  const { route } = router;
  const shouldProtect = routeConfig.auth_protected_routes.includes(route);

  const validateAuth = async () => {
    await axiosInstance
      .get('user/userinfo', {
        withCredentials: true,
      })
      .catch(() => router.push(defaultUnprotectedRouteRedirect));
  };

  useEffect(() => {
    if (shouldProtect) {
      validateAuth();
      router.events.on('routeChangeStart', validateAuth);
    }
    return () => {
      router.events.off('routeChangeStart', validateAuth);
    };
  }, [router.pathname]);

  return children;
};
