import { axiosInstance } from './axios.interceptors';

export const getMyNotifications = async (page: number) => {
  const res = await axiosInstance.get('/notifications?page=' + page, {
    withCredentials: true,
  });
  return res.data;
};

export const readNotifications = async (ids: Array<string>) => {
  const res = await axiosInstance.post(
    '/notifications',
    { notifications: ids },
    {
      withCredentials: true,
    },
  );
  return res.data;
};
