import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMyNotifications } from 'api/notifications.service';

export const fetchNotifications = createAsyncThunk(
  'get/notificatins',
  async (page: number) => {
    const notifications = await getMyNotifications(page);
    return { items: notifications?.[0], meta: { ...notifications[1], page } };
  },
);
