import React from 'react';
import CloseIcon from '@a/../../assets/close.svg';
import Typography, { TypographyVariants } from '@c/Typographies/Typography';
import { theme } from 'theme/theme';
import Typography2 from '@c/Typographies/Typography2';

type ModalHeaderProps = {
  onClose: () => void;
  title: string;
  subtitle?: string;
};

const ModalHeader = ({ onClose, title, subtitle }: ModalHeaderProps) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Typography2 weight="600" size="l">
          {title}
        </Typography2>
        <div style={{ position: 'absolute' }}>
          <Typography2
            size="s"
            weight="400"
            color={theme.palette.textTransluent.main}
          >
            {subtitle}
          </Typography2>
        </div>
      </div>
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <CloseIcon fill="rgba(0,0,0,0.3)" />
      </div>
    </div>
  );
};

export default ModalHeader;
