import { RatingObject } from '@c/Ratings/RatingsInput';
import { axiosInstance } from './axios.interceptors';

export const resetPassword = async (data) => {
  const res = await axiosInstance.post('/user/password-reset', data, {
    withCredentials: true,
  });
  return res.data;
};

export type LoginData = {
  password: string;
  username: string;
  rememberMe: boolean;
};

export const loginUser = async (data: LoginData) => {
  const res = await axiosInstance.post('/user/', data, {
    withCredentials: true,
  });
  return res.data;
};

export type RegisterData = {
  username: string;
  email: string;
  password: string;
  type: 'client' | 'girl';
};

export const registerUser = async (data: RegisterData) => {
  const res = await axiosInstance.post('/user/register', data, {
    withCredentials: true,
  });
  return res.data;
};

export const rateGirl = async (
  ratingObject: Partial<RatingObject>,
  girlId: string,
) => {
  const res = await axiosInstance.post('ratings/rate/' + girlId, ratingObject, {
    withCredentials: true,
  });
  return res.data;
};

export const checkUsername = async (username: string, email: string) => {
  const res = await axiosInstance.post('user/check-username', {
    username,
    email,
  });
  return res.data;
};

export const checkEmail = async (username: string, email: string) => {
  const res = await axiosInstance.post('user/check-email', {
    username,
    email,
  });
  return res.data;
};

export const uploadAvatar = async (avatar: File) => {
  const formData = new FormData();
  formData.append('avatar', avatar);
  const res = await axiosInstance.post('user/avatar/upload', formData, {
    withCredentials: true,
  });
  return res.data;
};

export const tryApproveCode = async (code: string) => {
  const res = await axiosInstance.post(
    'user/verification/approve',
    { code },
    {
      withCredentials: true,
    },
  );
  return res;
};

export const getPaymentOptions = async () => {
  const res = await axiosInstance.get('/user/payments', {
    withCredentials: true,
  });
  return res.data;
};

export const requestResetPassword = async (emailOrUserId: string) => {
  const res = await axiosInstance.post('/user/password/reset', {
    emailOrUserId,
  });
  return res.data;
};

export const setNewPassword = async (
  newPassword: string,
  reset_session: string,
) => {
  const res = await axiosInstance.post('/user/password/set', {
    password: newPassword,
    actionId: reset_session,
  });
  return res.data;
};

export const getUserBalanceReport = async () => {
  const res = await axiosInstance.get('/service/report', {
    withCredentials: true,
  });
  return res.data;
};

export const getUserBalance = async () => {
  const res = await axiosInstance.get('service/user/balance', {
    withCredentials: true,
  });
  return res.data?.balance;
};

export const checkIsPaidForToday = async (id: string) => {
  const res = await axiosInstance.get(`service/user/paid/${id}`, {
    withCredentials: true,
  });
  return res.data;
};

export const resendCode = async () => {
  const res = await axiosInstance.get(`user/code/resend`, {
    withCredentials: true,
  });
  return res.data;
};

export const getUserType = async (cookie?: string | undefined) => {
  if (cookie) {
    const res = await axiosInstance.get(`user/type`, {
      withCredentials: true,
      headers: { Cookie: cookie },
    });
    return res.data;
  } else {
    const res = await axiosInstance.get(`user/type`, { withCredentials: true });
    return res.data;
  }
};
